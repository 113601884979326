<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
            <div class="sectionBody">
                <div class="d-flex justify-content-end">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 section-header mb-2 p-0">
                        <span class="section-title">{{this.admin_data.guideInventory}}</span>
                    </div>
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right" style="min-height: 40px;">
                        <button type="button" class="btn btn-secondary ml-2"
                            (click)="getGuides()">{{this.admin_data.refresh}}</button>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-flex align-items-center justify-content-between">
                        <div id="select-app" style="min-width: 35%;">
                            <!-- <ng-multiselect-dropdown class="p-0 font14 text-nowrap" [placeholder]="appsText"
                                    [data]="dropdownList" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                    (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)"
                                    (onSelectAll)="onSelectAll()" (onDeSelectAll)="onDeSelectAll()">
                                    {{this.admin_data.selectApplications}}
                                </ng-multiselect-dropdown> -->
                            <app-multi-checkbox-dropdown componentName="guideInventory" [appList]="dropdownList"
                                [placeholder]="appsText" (updateAppList)="updateAppList($event)"
                                selectAll="true"></app-multi-checkbox-dropdown>
                        </div>
                        <div class="input-group d-inline-flex ml-2" style="width: 292px !important;">
                            <input class="form-control py-2 background-icon"
                                placeholder={{this.admin_data.selectDatesPlaceholder}} type="text" name="daterange"
                                autocomplete="off" />
                        </div>
                        <div id="filter" class="ml-2 filter-dropdown">
                            <button class="btn-primary-borderless-medium" id="filter-menu"
                            (click)="showFilterMenu = !showFilterMenu" data-toggle="dropdown">
                                <span class="d-flex align-items-center" style="min-width: max-content">
                                    <span class="mr-2">
                                        <img src="../../../../assets/svgs/filter_new.svg"/>
                                    </span>
                                    <span>{{admin_data.filter}}</span>
                                </span>
							</button>
                            <div class="dropdown-menu pb-0" role="menu" [ngClass]="{'show': showFilterMenu}"
                                id="filter-dropdown-menu">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left px-4 pt-3 mb-4">
                                    <div class="d-flex justify-content-between align-items-baseline">
                                        <span class="fontWeight-900 font16 text-color-dark">{{this.admin_data.filters}}</span>
                                        <div class="d-flex justify-content-end align-items-center">
                                            <span type="button" class="primaryFont-color mx-2 px-2 font13 fontWeight-700" (click)="clear()">
                                                {{this.admin_data.clear}}</span>
                                            <button type="button" class="btn btn-primary-small"
                                                (click)="applyFilter(status.value, guide_type.value);$event.stopPropagation()">
                                                {{this.admin_data.apply}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <div class=" form-group px-4 mb-4">
                                        <label class="fontWeight-900 font14 text-color-dark-grey"
                                            for="status">{{this.admin_data.usersVariable2}}</label>
                                        <!-- <select #status class="form-control form-control-sm" id="status">
                                                <option value="" [selected]="params.is_published===''">
                                                    {{this.admin_data.usersVariable3}}
                                                </option>
                                                <option value="1" [selected]="params.is_published==='1'">
                                                    {{this.admin_data.published}}
                                                </option>
                                                <option value="0" [selected]="params.is_published === '0'">
                                                    {{this.admin_data.unpublished}}
                                                </option>
                                            </select> -->

                                        <app-custom-select #status [dataList]="customSelectObj"
                                            (updateDataList)="status.value = $event; params.is_published = $event;" [selected]="params.is_published"
                                            componentName="guides" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
                                    </div>
                                    <div class="form-group px-4 mb-4">
                                        <label class="fontWeight-900 font14 text-color-dark-grey"
                                            for="guide_type">{{this.admin_data.guideType}}</label>
                                        <!-- <select #guide_type class="form-control form-control-sm" id="guide_type">
                                            <option value="" [selected]="guide_type===''">{{this.admin_data.usersVariable3}}
                                            </option>
                                            <option *ngFor="let type of GuideTypeMap | keyvalue" [value]="type.key"
                                                [selected]="guide_type===type">{{type.value}}</option>
                                        </select> -->

                                        <app-custom-select #guide_type [dataList]="guideTypeArray"
                                            (updateDataList)="guide_type.value = $event; params.guide_type = $event" [selected]="params.guide_type"
                                            className2 = "guide-type-drop"
                                            componentName="default" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="search" class="ml-1">
                            <div class="input-group d-inline-flex width-300">
                                <input class="form-control py-2 search" type="search"
                                    placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                    (input)="search()" (search)="search()" aria-label="Search"
                                    [(ngModel)]="this.params.search_text">
                                <!-- <span class="input-group-append no-border">
                                        <div class="input-group-text bg-white">
                                            <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                                        </div>
                                    </span> -->
                            </div>
                        </div>
                        <div *ngIf="notACreator()" class="ml-2">
                            <button class="btn btn-primary" (click)="exportGuides()"
                                [disabled]="this.tableData.length < 1">
                                <span class="d-inline-flex m-auto">
                                    <!-- <svg-icon src="../../assets/svgs/download_icon.svg" class="download-icon"
                                            [applyClass]="trueValue"></svg-icon> -->
                                    <span class="ml-1">{{this.admin_data.export}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex mt-4 scroll-x table-container p-0">
                        <table *ngIf="tableData.length>0">
                            <thead>
                                <th *ngFor="let heading of tableHeaders; index as i" class="py-1">
                                    <span class="d-inline-flex align-items-center">{{heading['heading']}}
                                        <span *ngIf="heading['sortable']" class="d-flex flex-column ml-2">
                                            <svg-icon *ngIf="params.sort_by != heading['key']" src="../../assets/svgs/sort-neutral-icon.svg"
                                                role="button" (click)="sort(heading['key'], 'asc')"></svg-icon>
                                            <svg-icon *ngIf="params.sort_by === heading['key'] && params.sort_order === 'asc'"
                                                src="../../assets/svgs/sort-asc-icon.svg" role="button" (click)="sort(heading['key'], 'desc')"></svg-icon>
                                            <svg-icon *ngIf="params.sort_by === heading['key'] && params.sort_order === 'desc'"
                                                src="../../assets/svgs/sort-desc-icon.svg" role="button"
                                                (click)="sort(heading['key'], 'asc')"></svg-icon>
                                            <!-- <span
                                                *ngIf="params.sort_by != heading['key']  || params.sort_order !== 'asc'"
                                                style="position:relative;top:5px; right:0" role="button"
                                                (click)="sort(heading['key'], 'asc')">
                                                <svg-icon src="../../assets/svgs/sort-up-arrow.svg" class="up-arrow"
                                                    [applyClass]="trueValue"></svg-icon>
                                            </span>
                                            <span *ngIf="params.sort_by == heading['key'] && params.sort_order == 'asc'"
                                                style="position:relative;top:5px; right:0" role="button"
                                                (click)="sort(heading['key'], 'asc')"><svg-icon
                                                    src="../../assets/svgs/sort-up-arrow.svg" class="up-arrow-filled"
                                                    [applyClass]="trueValue"></svg-icon></span>
                                            <span
                                                *ngIf="params.sort_by !== heading['key'] || params.sort_order !== 'desc'"
                                                style="position:relative;top:-3px; right:0" role="button"
                                                (click)="sort(heading['key'], 'desc')"><svg-icon
                                                    src="../../assets/svgs/sort-down-arrow.svg" class="down-arrow"
                                                    [applyClass]="trueValue"></svg-icon></span>

                                            <span
                                                *ngIf="params.sort_by == heading['key'] && params.sort_order == 'desc'"
                                                style="position:relative;top:-3px; right:0" role="button"
                                                (click)="sort(heading['key'], 'desc')"><svg-icon
                                                    src="../../assets/svgs/sort-down-arrow.svg" class="up-arrow-filled"
                                                    [applyClass]="trueValue"></svg-icon></span>-->
                                        </span>
                                    </span>
                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tableData; index as i">
                                    <td [ngStyle]="{'min-width' : '300px'}">{{data.guideTitle}}</td>
                                    <td [ngStyle]="{'min-width' : '230px'}">{{data.type}}</td>
                                    <td [ngStyle]="{'min-width' : '150px'}">{{data.folder}}</td>
                                    <td [ngStyle]="{'min-width' : '230px'}">{{data.createdBy}}</td>
                                    <td [ngStyle]="{'min-width' : '235px'}">{{data.created * 1000 | date : "EEE, MMM d,
                                        y"}}</td>
                                    <td [ngStyle]="{'min-width' : '230px'}">{{data.modified * 1000 | date : "EEE, MMM
                                        d,y"}}</td>
                                    <td [ngStyle]="{'min-width' : '150px'}">{{data.status}}</td>
                                    <td [ngStyle]="{'min-width' : '200px'}" class="d-inline-flex align-items-center"
                                        data-bs-toggle="tooltip" data-placement="right">
                                        <ng-container *ngFor="let item of data.publish_env; index as i">


                                            <span class="badge rounded-pill displayFlex alignItemsCenter">
                                                <span class="badge-text">{{item['name']}}</span>

                                            </span>

                                            <!-- 
                                                <span data-bs-toggle="tooltip" data-placement="right"
                                                    class="badge rounded-pill badge-primary font14 h-3 mx-1 my-1">{{item['name']}}</span> -->
                                            <!-- <span *ngIf="i==3">...</span>-->
                                        </ng-container>
                                    </td>
                                    <td>
                                        <span class="d-flex">
                                            <ng-container *ngIf="notACreator()">
                                                <span role="button" class="mr-2" (click)="openActionModal('Publish', i )">
                                                    <svg-icon src="../../assets/svgs/publish-icon.svg" data-toggle="tooltip" data-placement="bottom"
                                                        title={{this.admin_data.publish}}></svg-icon>
                                                </span>
                                                <span [class.disabled]="data.status === admin_data.unpublished"
                                                    [ngStyle]="{'pointer-events': data.status === admin_data.unpublished ? 'none' : 'visible' }"
                                                    role="button" (click)="openActionModal('Unpublished', i)">
                                                    <svg-icon src="../../assets/svgs/unpublish-icon.svg" data-toggle="tooltip" data-placement="bottom"
                                                        title={{this.admin_data.unpublish}}></svg-icon>
                                                </span>
                                    
                                            </ng-container>
                                            <ng-container *ngIf="data['status'] === admin_data.published && canGenerateVideo(data)">
                                                <span *ngIf="data['video_status'] === 'DONE'" class="ml-2">
                                                    <svg-icon role="button" (click)="downloadVideos(data)" src="../../assets/svgs/download_icon.svg"
                                                        data-toggle="tooltip" data-placement="bottom" title={{this.admin_data.downloadVideos}}></svg-icon>
                                                </span>
                                                <span
                                                    *ngIf="data['video_status'] === 'NA' || data['video_status'] === 'FAILED' || data['video_output_data'] == []"
                                                    role="button" (click)="triggerVideoCreation(data['guideId'])" class="ml-2">
                                                    <svg-icon src="../../assets/svgs/video-icon.svg" data-toggle="tooltip" data-placement="bottom"
                                                        title={{this.admin_data.generateVideo}}></svg-icon>
                                                </span>
                                                <span *ngIf="data['video_status'] === 'In Progress'" class="ml-2">
                                                    <svg-icon src="../../assets/svgs/hourglass-half.svg" data-toggle="tooltip" data-placement="bottom"
                                                        title={{this.admin_data.videoGenerationInProgress}}></svg-icon>
                                                </span>
                                            </ng-container>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="tableData.length == 0" class="no-guide">
                            <img src="./../../assets/svgs/no_guides_image.svg">
                            <div class="no-guide-label-1">
                                <p>{{this.admin_data.noGuideFound}}</p>
                            </div>
                            <div class="no-guide-label-2">
                                <p>{{this.admin_data.modifySearchCriteria}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <app-pagination [offset]="params.offset" [totalRecords]="paginationService.total"
                    [currentPage]="paginationService.currentPage" [limit]="params.limit"
                    (gotoFirstPage)="gotoFirstPage()" (gotoLastPage)="gotoLastPage($event)"
                    (gotoPreviousPage)="prependOffset()" (gotoNextPage)="appendOffset()"
                    (changeLimit)="changeLimit($event)" (gotoAction)="gotoPage($event)"></app-pagination>
            </div>
        </section>

        <div class="modal fade" tabindex="-1" role="dialog" id="actionModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">{{admin_data.publishGuide}}</h6>
                    </div>
                    <div class="modal-body font14 ">
                        <div class="d-flex flex-column">
                            <div *ngFor="let env of curr_appEnv; index as i" class="d-flex flex-row publish-area">
                                <span class="d-flex flex-column">
                                    <span *ngIf="i>0" class="vertical-line py-0">|</span>
                                    <span>
                                        <button class="btn btn-outline-primary rounded-pills p-0 m-0"
                                            (click)="changeInPublish(env['order'])"
                                            [ngClass]="{'hover' : env['checked'] === true, 'remove-hover' : env['checked'] === false}"></button>
                                        <span class="px-4">{{env['name']}}</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="isInProduction()">
                            <div class="mx-4 mt-4">
                                <label class="font14">{{admin_data.setVisibilitySetting}}</label>
                                <label class="switch mx-2 mb-0"
                                    [ngClass]="disableVisibilitySettings ? ' switch-disabled' : ''"
                                    (click)="toggleCurrentVisibility($event.target.checked)">
                                    <input class=" primary" type="checkbox" [disabled]="disableVisibilitySettings"
                                        [checked]="this.currentTourSettings['visibilitySettings'] && this.currentTourSettings['visibilitySettings']['setvisibiltyrule'] === 1 ? true: false" />
                                    <span class="slider round" (click)="$event.stopPropagation()"></span>
                                </label>
                            </div>
                            <div class="mx-4"
                                *ngIf="this.currentTourSettings['visibilitySettings'] && this.currentTourSettings['visibilitySettings']['setvisibiltyrule'] === 1">
                                <div class="form-inline mb-2">
                                    <label class="font14 mr-2" for="date">{{admin_data.date}}</label>
                                    <input type="date"
                                        [ngStyle]="{'border': (invalidDateTime) ? '1px solid #DE350B !important' : ''}"
                                        (input)="changeDate($event)" id="date" min={{minDate}}
                                        [disabled]="disableVisibilitySettings" class="form-control px-2"
                                        [value]="this.visibilityDate" onkeydown="return false">
                                </div>
                                <div class="form-inline">
                                    <label class="time-input font14 mr-2" for="time">{{admin_data.time}}</label>
                                    <input type="time" class="form-control px-2"
                                        [ngStyle]="{'border': (invalidDateTime) ? '1px solid red !important' : ''}"
                                        [disabled]="disableVisibilitySettings" min={{minTime}}
                                        [value]="this.visibilityTime" onkeydown="return false"
                                        (input)="changeTime($event)">
                                </div>
                                <div *ngIf="invalidDateTime && !disableVisibilitySettings" class="mx-2 mt-3 error">
                                    {{admin_data.serverMessage135}}</div>
                            </div>
                        </div>
                        <div class="m-2 pt-4 text-center">
                            <span>{{currModalString}}</span>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-end">
                        <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                            (click)="closeModal()">{{this.admin_data.cancelButtonText}}</button>
                        <button type="button"
                            [disabled]="invalidDateTime || (currModalString == '') || !publishingChanged || publishingUnpublishingInProgress ||(invalidCombinationOfvisibilitySettings())"
                            class="btn btn-primary minWidth-120 mx-2"
                            (click)="actionOnModal()">{{this.admin_data.publish}}</button>
                        <!-- <app-button [buttonData]="userService.delete_button" (clickhandler)="delete()"></app-button>
                    <button class="btn btn-primary" (click)="delete()">{{modalOkValue}}</button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>