import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    constructor(
        private userService: UserService,
        private apiService: ApiService
    ) { }

    register(){
        let urlPath = environment.INSIGHTS_HOST + API_END_POINTS.REGISTER_CLIENT;
        return this.apiService.post(urlPath,null);
    }

    getClientSecret(){
        let urlPath = environment.INSIGHTS_HOST + API_END_POINTS.GET_CLIENT_SECRET;
        if(!localStorage.getItem('app_client_id')){
            this.register().subscribe((response)=> {
                if(response['data']){
                    localStorage.setItem('app_client_id', response['data']['app_client_id'])
                    let req = {
                        app_client_id: localStorage.getItem('app_client_id')
                    }
                    return this.apiService.post(urlPath, req);
                }
            })
        }else {
            let req = {
                app_client_id: localStorage.getItem('app_client_id')
            }
            return this.apiService.post(urlPath, req);
        }
    }

    pushEvents(req){
        let urlPath = environment.INSIGHTS_HOST + API_END_POINTS.PUSH_EVENT;
        return this.apiService.post(urlPath, req);
    }

    getUUID(){
        return uuidv4();
    }

    async sha256(message) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string                  
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }
    getPayload(type){
        console.log(this.getUUID());
    }
}
